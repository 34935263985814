import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { linkResolver } from "../utils/linkResolver"
import Single from "../components/single/single"
import Seo from "../components/seo"

const DocumentTemplate = ({ data }) => {
  const pageData = data.prismicDocument.data

  return (
    <Single heading={pageData.heading}>
      <Seo
        title={pageData.meta_title || pageData.heading}
        description={pageData.meta_description}
      />
      {pageData.body.html && (
        <div
          className="rich-text mb-14 sm:mb-18"
          dangerouslySetInnerHTML={{
            __html: pageData.body.html,
          }}
        ></div>
      )}
    </Single>
  )
}

export const query = graphql`
  query DocumentTemplate($id: String) {
    prismicDocument(id: { eq: $id }) {
      _previewable
      data {
        heading
        body {
          html
        }
        meta_title
        meta_description
      }
    }
  }
`

export default withPrismicPreview(DocumentTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])
